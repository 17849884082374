import { AdditionalBenefitType } from '~/generated/api-clients-generated'

export type AdditionalBenefitsVariant = null | 'compact' | 'detailed'

export class AdditionalBenefits {
  static async use(variant: AdditionalBenefitsVariant = null) {
    const { getComponentContent } = Cms.use()

    const { content } = await getComponentContent('AdditionalBenefits', variant)

    const additionalBenefitOptions = computed(() => [
      {
        value: AdditionalBenefitType.Vision,
        text: content.value?.ext?.option_vision_title,
        icon: content.value?.ext?.option_vision_icon
      },
      {
        value: AdditionalBenefitType.Hearing,
        text: content.value?.ext?.option_hearing_title,
        icon: content.value?.ext?.option_hearing_icon
      },
      {
        value: AdditionalBenefitType.Dental,
        text: content.value?.ext?.option_dental_title,
        icon: content.value?.ext?.option_dental_icon
      },
      {
        value: AdditionalBenefitType.Fitness,
        text: content.value?.ext?.option_fitness_title,
        icon: content.value?.ext?.option_fitness_icon
      },
      {
        value: AdditionalBenefitType.Transportation,
        text: content.value?.ext?.option_transportation_title,
        icon: content.value?.ext?.option_transportation_icon
      },
      {
        value: AdditionalBenefitType.DomesticTravel,
        text: content.value?.ext?.option_domestic_travel_title,
        icon: content.value?.ext?.option_domestic_travel_icon
      },
      {
        value: AdditionalBenefitType.OtcMedications,
        text: content.value?.ext?.option_otc_title,
        icon: content.value?.ext?.option_otc_icon
      }
    ])

    const getBenefitText = (type: AdditionalBenefitType) =>
      additionalBenefitOptions.value.find((x) => x.value == type)?.text ?? null

    return {
      additionalBenefitOptions,
      getBenefitText
    }
  }
}
